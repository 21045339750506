import * as React from "react"
import Layout from "../components/layout"
import logo from "../images/logo.svg"
import { StaticImage } from "gatsby-plugin-image"

export default function IndexPage() {
  return (
    <Layout>
      <div className="flex h-screen">
        <div className="m-auto">
          <div className="flex justify-center items-center m-auto">
            <div class="object-cover w-80 lg:w-[32rem]">
              <StaticImage
                placeholder="tracedSVG"
                loading="eager"
                src="../images/logo.svg"
                alt="logo"
              />
            </div>
          </div>
          <div className="text-center text-base md:text-xl">
            <a href="menu">Meny</a> &middot; <a href="lunch">Lunch</a> &middot;{" "}
            <a href="find-us">Kontakt</a> &middot;{" "}
            <a href="opening-hours">Öppettider</a>
            <br />
            <br />
            <div class="text-center text-base md:text-xl">
              Fira pappa på söndag hos oss! <br />
              Vi har öppet 12:00-21:00 <br />
              <br />
              Boka bord på 0550-82320 eller maila oss
              <br />
              <a href="mailto:lacucinakristinehamn@gmail.com">
                lacucinakristinehamn@gmail.com
              </a>
              <br />
              <br />
              Välkommen! <br />
              <a href="fathers-day" class="underline">
                Gå till erbjudande
              </a>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </Layout>
  )
}
